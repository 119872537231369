import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static outlets = [ "stream-fetcher-item" ]
  static values = {
    url: String
  }

  initialize() {
    this.clearQueue()
  }

  streamFetcherItemOutletConnected(outlet, element) {
    this.startQueue()
    this.queue.push(outlet.idValue)
    this.log("Connected Target:", element)
  }

  startQueue() {
    if(this.queueIntervalId) { return }
    this.log("Start queue")
    this.queueIntervalId = setInterval(() => this.processQueue(), 5);
  }

  stopQueue() {
    this.log("Stop queue")
    clearInterval(this.queueIntervalId)
    this.queueIntervalId = null
  }

  clearQueue() {
    this.queue = []
  }

  processQueue() {
    let ids = this.queue
    this.clearQueue()
    this.stopQueue()
    this.log("Processing Queue", ids)

    fetch(
      this.url(ids), {
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })
      .then(response => response.text())
      .then(text => Turbo.renderStreamMessage(text))
  }

  log(...message) {
    //console.log(this.urlValue, ...message)
  }

  url(ids) {
    return `${this.urlValue}?id=${ids.join(",")}`
  }
}
