import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  initialize() {
    if (!this.user) {
      document.addEventListener("fdb.userUpdate", this.render.bind(this))
      document.addEventListener("fdb.subscriptionUpdate", this.render.bind(this))
    }
  }

  get user() {
    return window.currentUser
  }

  get subscriptions() {
    return this.user && this.user.subscriptions
  }

  connect() {
    if (!this.user) return
    this.render()
  }

  render() {
    if (this.isSubscribed()) {
      this.element.classList.add("subscribed")
    } else {
      this.element.classList.remove("subscribed")
    }
  }

  isSubscribed() {
    return this.subscriptions &&
      this.subscriptions[this.type] &&
      this.subscriptions[this.type].indexOf(this.id) != -1
  }

  subscribe() {
    Rails.ajax({
      url: this.url,
      type: "POST"
    })
  }

  unsubscribe() {
    Rails.ajax({
      url: this.url,
      type: "DELETE"
    })
  }

  get type() {
    return this.data.get("type")
  }

  get url() {
    return this.data.get("url")
  }

  get id() {
    return Number(this.data.get("id"))
  }

}
