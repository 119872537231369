// disable googleVignette ads on turbo frames 
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    addEventListener("turbo:load", this.updateAsync.bind(this))
    addEventListener("turbo:frame-load", this.updateAsync.bind(this))
  }

  updateAsync(event) {
    setTimeout(() => { this.update(event) }, 0)
  } 

  update(event) {
    document.querySelectorAll("a[data-turbo-method],a[data-turbo-frame],turbo-frame a").forEach(element => {
      element.dataset.googleVignette = false
    })
  }
}
