import { Controller } from "@hotwired/stimulus"
import inflector from "./../libs/inflector"

export default class extends Controller {
  static values = {
    birth: String,
    death: String,
  }

  connect() {
    this.element.innerHTML = this.age
  }

  get birth() {
    return this.parseDate(this.birthValue)
  }

  get death() {
    return this.parseDate(this.deathValue) || new Date
  }

  get age() {
    let years = this.years
    return `${years} ${inflector.inflect("rok", years)}`;
  }

  get years() {
    let birth = this.birth
    let death = this.death
    let years = (death.getFullYear() - birth.getFullYear());

    if ((death.getMonth() < birth.getMonth()) || ((death.getMonth() === birth.getMonth()) && (death.getDate() < birth.getDate()))) {
      years--
    }

    return years;
  }

  parseDate(date) {
    if ((typeof date === "string") && (date !== "")) {
      date = date.split("-");
      return new Date(date[0], parseInt(date[1])-1, (date[2] || 1));
    } else if (typeof date === "number") {
      return new Date(date, 1, 1);
    }
  }
}
