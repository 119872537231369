import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 1000 },
    url: String,
    type: { type: String, default: "turbo" }
  }

  connect() {
    this.destroyed = false
    this.visible = false
    document.addEventListener("visibilitychange", this.windowVisibilityChange.bind(this))
    useIntersection(this)
  }

  disconnect() {
    this.destroyed = true
    this.clearSchedule()
  }

  appear(entry, observer) {
    this.visible = true
    console.log(`Apper: resume pulling ${this.urlValue}`)
    this.schedule()
  }

  disappear(entry, observer) {
    this.visible = false
    console.log(`Disapper: pause pulling ${this.urlValue}`)
    this.clearSchedule()
  }

  windowVisibilityChange() {
    if (this.visible == false) return
    if (this.destroyed) return

    if (document.hidden) {
      console.log(`pause pulling ${this.urlValue}`)
      this.clearSchedule()
    } else {
      console.log(`resume pulling ${this.urlValue}`)
      this.schedule();
    }
  }

  clearSchedule() {
    if (this.timeoutId) clearTimeout(this.timeoutId)
  }

  schedule() {
    if (!this.destroyed) {
      this.timeoutId = setTimeout(this.pull.bind(this), this.intervalValue)
    }
  }

  pull() {
    console.log(`pulling ${this.urlValue}`)

    if (this.isTurbo) {
      this.turboPull()
    } else {
      this.jsPull()
    }
  }

  turboPull() {
    fetch(
      this.urlValue, {
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })
      .then(response => response.text())
      .then(text => Turbo.renderStreamMessage(text))
      .finally(this.schedule.bind(this))
  }

  jsPull() {
    Rails.ajax({
      url: this.urlValue,
      type: "get",
      success: this.schedule.bind(this),
      error: this.schedule.bind(this)
    })
  }

  get isTurbo() {
    return this.typeValue === "turbo"
  }
}
