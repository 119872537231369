import { Tabs } from "tailwindcss-stimulus-components"
import { useIntersection } from "stimulus-use"

export default class extends Tabs {
  initialize() {
    super.initialize()
  }
  connect() {
    super.connect()
    useIntersection(this)
  }

  appear(entry, observer) {
    if (this.updateAnchorValue && this.anchor) this.indexValue = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
    this.showTab()
  }

}
