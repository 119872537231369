/*
 *  this script is used by login with facebook => rewrite to stimulus
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class Facebook {
  constructor() {
    this.addObservers();
  }

  addObservers() {
    return this.observerConnect();
  }

  observerConnect() {
    return $(document).on("click", "[data-facebook-connect]", event => {
      event.preventDefault();
      if (window["FB"]) {
        return this.connect();
      } else {
        console.log("no facebook sdk found!")
      }
    });
  }

  connect() {
    return FB.login(
      response => {
        if ((response.status === "connected") && (response.authResponse)) {
          return this.createSession(response.authResponse);
        }
      },{
      scope: 'email',
      auth_type: 'rerequest'
    }
    );
  }

  submitFormEvent() {
    return new Event('submit', {bubbles: true, cancelable: true});
  }

  createSession(authResponse) {
    $('#facebook-connect-form-access-token').val(authResponse.accessToken);
    return $('#facebook-connect-form')[0].dispatchEvent(this.submitFormEvent());
  }
}

new Facebook();
