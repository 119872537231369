import Dropdown from "@stimulus-components/dropdown"

/**
 * show hidden is required on dropdown-menu until we use boostrap. dropdown-menu is hidden by default but this class require
 * visible dropdown-menu and hide it via hidden class.
 *
<div data-controller="dropdown" class="relative">
  <button type="button" class="dropdown-toggle btn btn-primary" data-action="dropdown#toggle click@window->dropdown#hide">
    Options <span class="caret"></span>
  </button>

  <turbo-frame class="dropdown-menu show hidden" loading="lazy" require="user" data-dropdown-target="menu" id="lists" src="">
    <div class="dropdown-item">Wczytuje...</div>
  </turbo-frame>
</div>
**/

export default class extends Dropdown {
  connect() {
    super.connect()
  }

  toggle(event) {
    super.toggle()
  }

  hide(event) {
    super.hide(event)
  }
}
