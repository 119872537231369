import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.dispatchConnect()
  }

  dispatchConnect() {
    this.dispatch("connect")
  }

  requireUser(event) {
    if (!this.isAuthenticatedUser) {
      event.stopImmediatePropagation()
      event.preventDefault()
      this.authenticate()
    }
  }

  authenticate() {
    authenticationModal.show("#signin")
  }

  get isAuthenticatedUser() {
    return document.querySelector('meta[name=user-id]').getAttribute("content") != null
  }
}
