const $ = jQuery;

window.FdbAsyncCaller = {
  onLoad(handler) {
    return handler();
  },

  affilate(type, pattern, link) {
    return Affilate[type](pattern, link);
  },

  displayAds(unit) {
    const ads = new Ads(unit);
    return ads.display();
  },

  displayAdsReplacment(unit) {
    const adsReplacment = new AdsReplacment(unit);
    adsReplacment.replace();
    return gtag('event', 'AdsReplacment', { "unit": unit });
  },

  displayAdsScreening(image, url, view) {
    return AdsScreening.display(image, url, view);
  },

  updateCurrentUser(user) {
    return UserUpdater.update(user);
  },

  adultsMark() {
    return Adults.mark();
  },

  postscribe(selector, content) {
    const handler = new PostscribeHandler(selector, content);
    return handler.call();
  }
};
