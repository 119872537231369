import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = [ "dialog" ]

  connect() {
    this.dispatch("connect")
  }

  open() {
    this.dialogOutlet.open()
  }

  close() {
    this.dialogOutlet.close()
  }
}
