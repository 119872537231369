class Inflector {
  static definitions = {
    "rok": ["lata", "lat"]
  }

  static inflect(singular, count) {
    const plural = this.definitions[singular];
    if (!plural) { throw "Unknow word"; }

    if (count === 1) { return singular; }

    if (count > 19) { count = count % 10; }

    if ((count >= 2) && (count <= 4)) {
      return plural[0];
    } else {
      return plural[1];
    }
  }
}

export default Inflector 
