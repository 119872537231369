import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import { computePosition, autoUpdate, flip, shift, offset } from "@floating-ui/dom"

export default class extends Controller {
  static targets = ["content"]
  static classes = ["tooltip"]

  static values = {
    placement: { type: String, default: "right" },
    content: { type: String, default: "Missing content" },
    cache: { type: Boolean, default: false },
    container: { type: String, default: "popovers" }
  }

  initialize() {
    this.id = this.generateId()
  }

  connect() {
    this.element.classList.add(this.id)
    this.isVisible = false
    useClickOutside(this, { element: this.element })
  }

  disconnect() {
    this.element.classList.remove(this.id)
  }

  clickOutside(event) {
    if (!this.isVisible) { return }
    if (this.tooltip.contains(event.target)) { return }
    this.hide()
  }

  toggle(event) {
    event.preventDefault()

    if(this.isVisible) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.isVisible = true

    if (!this.tooltip || !this.cacheValue) {
      this.tooltip = this.createTooltip()
    }
    this.container.appendChild(this.tooltip)
    this.autoUpdate = autoUpdate(this.element, this.tooltip, this.update.bind(this))
  }

  hide() {
    this.isVisible = false
    this.tooltip.remove()
    this.autoUpdate()
  }

  update() {
    computePosition(this.element, this.tooltip, this.options).then(({x, y}) => {
      Object.assign(this.tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      })
    })
  }

  setClass() {

  }

  get container() {
    return document.getElementById(this.containerValue) || this.createContainer()
  }

  createContainer() {
    const container = document.createElement("div")
    container.id = this.containerValue
    document.body.appendChild(container)

    return container
  }

  createTooltip() {
    const tooltip = document.createElement("div")
    tooltip.classList.add(...this.tooltipClasses)
    tooltip.style.position = "absolute"
    tooltip.style.width = "max-content"
    tooltip.style.zIndex = "99999"
    tooltip.dataset.controller = "popover-action"
    tooltip.dataset.popoverActionPopoverOutlet = `.${this.id}`

    if (this.hasContentTarget) {
      tooltip.innerHTML = this.contentTarget.innerHTML
    } else {
      tooltip.innerHTML = this.contentValue
    }
    return tooltip
  }

  generateId() {
    let prefix = "popover"

    do {
      prefix += Math.floor(Math.random() * 1000000)
    } while (document.querySelector(`.${prefix}`))

    return prefix
  }

  get options() {
    return {
      placement: this.placementValue,
      middleware: this.middleware
    }
  }

  get middleware() {
    return [offset(5), flip(), shift({padding: 5})]
  }
}
